<template>
  <header class="pointer-events-none sticky top-0 z-header">
    <div class="pointer-events-none flex items-start">
      <div class="w-0" />
      <div
        ref="headerEl"
        class="pointer-events-auto w-full"
      >
        <slot name="notifications" />
        <slot name="headerTopLinks" />
        <div class="pointer-events-auto bg-primary py-4 transition-all lg:py-0">
          <div class="container flex flex-row items-center">
            <div
              v-if="$slots.region"
              class="mr-auto flex items-center lg:hidden"
            >
              <slot name="region" />
            </div>
            <NuxtLink
              :to="props.baseUrl"
              class="block lg:hidden"
            >
              <UiLogo
                variant="white"
                title="Rawlplug"
              />
            </NuxtLink>
            <div
              v-if="$slots.icons"
              class="ml-auto flex gap-5 text-white"
            >
              <slot name="icons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { usePageHeaderUi } from '../../../composables/usePageHeaderUi'
import UiLogo from '../../UiLogo/UiLogo.vue'

interface UiHeaderPage {
  baseUrl: string
}

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<UiHeaderPage>()

const emit = defineEmits<{
  (e: 'change-height', id: number): void
  (e: 'change-sticky', value: string): void
  (e: 'hamburger-click'): void
}>()

const { init, isHeaderSticky } = usePageHeaderUi(emit)

const headerEl = ref<HTMLElement | null>(null)

onMounted(() => {
  if (headerEl.value) {
    init(headerEl.value)
  }
})

onUnmounted(() => document.removeEventListener('scroll', isHeaderSticky))
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
